/*
 * Copyright 2023-2024 NXP
 */

import React, { ReactElement, ReactNode, useRef } from "react";
import { IconButton, Menu } from "@mui/material";
import styles from "@/src/components/layout/Navbar/styles";
import { useMenuContext } from "@/src/contexts/menuContext";

interface UserMenuTabProps {
  icon: ReactElement;
  children: ReactNode;
  menuName: string;
  unseenCount?: number;
  id: string;
}

const UserMenuTab = (props: UserMenuTabProps) => {
  const { icon, children, menuName, unseenCount, id } = props;
  const anchorRef = useRef<null | HTMLButtonElement>(null);
  const menuCtx = useMenuContext();

  return (
    <>
      <IconButton
        data-testid={id}
        sx={styles.userTab}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          anchorRef.current = event.currentTarget;
          menuCtx.handleOpen(menuName);
        }}
        disableRipple
        color="inherit"
        ref={anchorRef}
      >
        {icon}
      </IconButton>
      <Menu
        slotProps={styles.menuDropdown()}
        anchorEl={anchorRef.current}
        open={menuCtx.openMenu === menuName}
        onClose={menuCtx.handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {children}
      </Menu>
    </>
  );
};

export default UserMenuTab;
