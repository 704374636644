/*
 * Copyright 2023-2024 NXP
 */

import { Button, ListItemButton, Menu, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRouter } from "@/src/navigation";
import styles from "./styles";
import { useTranslations } from "next-intl";
import { useGlobalContext } from "@/src/contexts/GlobalContext";
import { useSearchParams } from "next/navigation";
import { ListItemTextCentered } from "@/src/components/UI/ListElementsStyled/ListElementsStyled";

const ToolsNavigation = () => {
  const [open, setOpen] = useState(false);
  const searchParams = useSearchParams();
  const toolsCtx = useGlobalContext().configTools;

  const uvid = searchParams.get("uvid")
    ? +(searchParams.get("uvid") as string)
    : undefined;

  const t = useTranslations("ToolsNavigation");
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const router = useRouter();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    anchorRef.current = event.currentTarget;
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleGoBack = () => {
    if (uvid) router.push("/dashboard");
    else {
      router.push(`/select?hardware=${toolsCtx.toolsData?.name}`);
      toolsCtx.setToolsData(undefined);
    }
  };

  const handleRedirect = (url: string) => {
    router.push(uvid ? url + `?uvid=${uvid}` : url);
    handleClose();
  };

  return (
    <>
      <Stack direction={"row"} spacing={1} sx={styles.toolsButtonGroup}>
        <Button
          size={"small"}
          variant={"text"}
          color="inherit"
          startIcon={<KeyboardDoubleArrowLeftIcon />}
          onClick={handleGoBack}
        >
          {t("back")}
        </Button>
        <Button
          size={"small"}
          variant={"text"}
          color="inherit"
          endIcon={<ExpandMoreIcon />}
          onClick={handleOpen}
          ref={anchorRef}
        >
          {t("configTool")}
        </Button>
      </Stack>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        slotProps={styles.menuDropdown(true)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ListItemButton dense onClick={() => handleRedirect("/clock")}>
          <ListItemTextCentered primary={t("clocks")} />
        </ListItemButton>
        <ListItemButton dense onClick={() => handleRedirect("/pins")}>
          <ListItemTextCentered primary={t("pins")} />
        </ListItemButton>
      </Menu>
    </>
  );
};

export default ToolsNavigation;
