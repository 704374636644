/*
 * Copyright 2024 NXP
 */

import { ListItemText, ListItemTextProps } from "@mui/material";
import React from "react";
import COLORS from "@/src/lib/colors";

interface ListTextStyledProps extends ListItemTextProps {
  error?: boolean;
  boldTitle?: boolean;
  withoutColor?: boolean;
}

export const ListTextStyled = (props: ListTextStyledProps) => {
  const { error, boldTitle, withoutColor, ...rest } = props;
  return (
    <ListItemText
      primaryTypographyProps={{
        variant: "body2",
        fontWeight: !!boldTitle ? "bold" : "normal",
        color: !!withoutColor
          ? "initial"
          : !!error
            ? "maroon"
            : COLORS.teal[500],
      }}
      secondaryTypographyProps={{ variant: "caption" }}
      {...rest}
    />
  );
};
