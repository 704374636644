/*
 * Copyright 2023-2024 NXP
 */

import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItem } from "@mui/material";
import styles from "@/src/components/layout/Navbar/styles";
import List from "@mui/material/List";
import { useMenuContext } from "@/src/contexts/menuContext";
import { ListItemTextCentered } from "@/src/components/UI/ListElementsStyled/ListElementsStyled";
import { Maybe, MyUser } from "@/web-builder/api";
import LinkWithLocale from "@/src/components/UI/LinkWithLocale/LinkWithLocale";
import LinkWithoutLocale from "@/src/components/UI/LinkWithoutLocale/LinkWithoutLocale";

interface LinkListItem {
  id: string;
  text: string;
  url: string;
  icon: React.ReactElement;
  divider?: boolean;
  ignoreLocale?: boolean;
}

interface LinksSectionProps {
  user?: Maybe<MyUser>;
  links: LinkListItem[];
}

const NestedNavMenu = (props: LinksSectionProps) => {
  const { user, links } = props;
  const menuCtx = useMenuContext();

  return (
    <List sx={styles.nestedNavList}>
      {user && (
        <ListItem data-testid={"username"} dense divider>
          <ListItemText primary={user.email} sx={styles.linkSectionTitle} />
        </ListItem>
      )}

      {links.map((link) => (
        <React.Fragment key={link.text}>
          <ListItemButton
            component={link.ignoreLocale ? LinkWithoutLocale : LinkWithLocale}
            href={link.url}
            data-testid={link.id}
            divider={!!link.divider}
            onClick={menuCtx.handleClose}
            dense
          >
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemTextCentered primary={link.text} />
          </ListItemButton>
        </React.Fragment>
      ))}
    </List>
  );
};

export default NestedNavMenu;
