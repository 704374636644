/*
 * Copyright 2023-2024 NXP
 */

import React from "react";
import { Stack } from "@mui/material";
import UserMenuTab from "@/src/components/layout/Navbar/UserMenuTab";
import styles from "@/src/components/layout/Navbar/styles";
import CollapsibleMenu from "@/src/components/layout/Navbar/CollapsibleMenu";
import { useMenuContext } from "@/src/contexts/menuContext";
import TranslateIcon from "@mui/icons-material/Translate";
import LanguageMenu from "@/src/components/layout/Navbar/LanguageMenu";
import NotificationsMenu from "@/src/components/layout/Navbar/NotificationsMenu";
import PersonIcon from "@mui/icons-material/Person";
import NestedNavMenu from "@/src/components/layout/Navbar/NestedNavMenu";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslations } from "next-intl";
import SecurityIcon from "@mui/icons-material/Security";
import DescriptionIcon from "@mui/icons-material/Description";
import CallIcon from "@mui/icons-material/Call";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAuthContext } from "@/src/contexts/authContext";
import SupervisorAccountTwoToneIcon from "@mui/icons-material/SupervisorAccountTwoTone";
import {
  HelpCenter,
  LogoutTwoTone,
  Notifications,
  NotificationsTwoTone,
} from "@mui/icons-material";
import { StyledBadge } from "../../UI/BadgeStyled/BadgeStyled";

const UserMenu = () => {
  const quickNotsUnseenCount = useMenuContext().navbarNots.count;
  const user = useAuthContext().user?.myUser;

  const t = useTranslations("UserMenu");

  const footerLinks = [
    {
      text: t("privacyPolicy"),
      icon: <SecurityIcon />,
      id: "privacy-policy",
      url: "https://www.nxp.com/about/privacy:PRIVACYPRACTICES",
    },
    {
      text: t("terms"),
      icon: <DescriptionIcon />,
      id: "terms",
      url: "https://www.nxp.com/about/terms-of-use:TERMSOFUSE",
    },
    {
      text: t("contact"),
      icon: <CallIcon />,
      id: "contact",
      url: "https://www.nxp.com/about/contact-us:CONTACTUS",
    },
    {
      text: t("accessibility"),
      icon: <AccessibilityNewIcon />,
      id: "accessibility",
      url: "https://www.nxp.com/company/about-nxp/accessibility:ACCESSIBILITY",
    },
  ];

  const profileMenuItemsLinks = [
    {
      text: t("notifications"),
      icon: <Notifications />,
      id: "notifications",
      url: "/notifications",
    },
    {
      text: t("help"),
      icon: <HelpCenter />,
      id: "help",
      url: "/help",
    },
    {
      text: t("preferences"),
      icon: <SettingsIcon />,
      id: "preferences",
      url: "/preferences",
    },
    ...(!!user?.admin
      ? [
          {
            text: t("admin"),
            icon: <SupervisorAccountTwoToneIcon />,
            id: "admin",
            url: "/mcuxadmin",
            divider: true,
            ignoreLocale: true,
          },
        ]
      : []),
    {
      text: t("logout"),
      icon: <LogoutTwoTone />,
      id: "logout",
      url: "/logout",
      ignoreLocale: true,
    },
  ];

  const menuItems = [
    {
      id: "language-menu",
      text: t("language"),
      icon: <TranslateIcon />,
      content: <LanguageMenu />,
    },
    {
      id: "notification-menu",
      text: t("quickNots"),
      icon: (
        <StyledBadge
          invisible={!quickNotsUnseenCount}
          badgeContent={quickNotsUnseenCount}
        >
          <NotificationsTwoTone />
        </StyledBadge>
      ),
      content: <NotificationsMenu />,
    },
    {
      id: "about",
      text: t("about"),
      icon: <InfoIcon />,
      content: <NestedNavMenu links={footerLinks} />,
      showOnSmallScreen: true,
    },
    {
      id: "profile-menu",
      text: t("userMenu"),
      icon: <PersonIcon />,
      content: <NestedNavMenu user={user} links={profileMenuItemsLinks} />,
    },
  ];

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={0.5}
        sx={styles.inlineMenuIconsContainer}
      >
        {menuItems.map(
          (item) =>
            !item.showOnSmallScreen && (
              <UserMenuTab
                id={item.id}
                key={item.id}
                icon={item.icon}
                menuName={item.id}
              >
                {item.content}
              </UserMenuTab>
            ),
        )}
      </Stack>
      <CollapsibleMenu menuItems={menuItems} />
    </>
  );
};

export default UserMenu;
