/*
 * Copyright 2023-2024 NXP
 */

import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemIcon } from "@mui/material";
import styles from "@/src/components/layout/Navbar/styles";
import parse from "html-react-parser";
import ListItemButton from "@mui/material/ListItemButton";
import { VisibilityOff } from "@mui/icons-material";
import * as Sentry from "@sentry/nextjs";
import { useMenuContext } from "@/src/contexts/menuContext";
import { useTranslations } from "next-intl";
import { ListTextStyled } from "@/src/components/UI/ListElementsStyled/ListTextStyled";
import LinkWithLocale from "@/src/components/UI/LinkWithLocale/LinkWithLocale";

const NotificationsMenu = () => {
  const t = useTranslations("QuickNotifications");
  const [noNotsText, setNoNotsText] = useState(t("noNewNots"));
  const menuCtx = useMenuContext();
  const navbarNots = menuCtx.navbarNots;
  const notsData = navbarNots.queryRes.data;

  useEffect(() => {
    if (!!navbarNots.count) navbarNots.markAsSeen();
  }, []);

  if (navbarNots.queryRes.error) {
    Sentry.captureException(navbarNots.queryRes.error);
    return (
      <List>
        <ListItem>
          <ListTextStyled primary={t("oops")} secondary={t("error")} error />
        </ListItem>
      </List>
    );
  }

  if (!notsData?.myNotifications.edges.length) {
    return (
      <List>
        <ListItem>
          <ListTextStyled
            primary={noNotsText}
            secondary={t("willNotify")}
            boldTitle
          />
        </ListItem>
      </List>
    );
  }

  return (
    <List sx={styles.nestedNavList}>
      <List sx={styles.newsList}>
        {notsData?.myNotifications.edges.map((edge) => {
          const not = edge?.node;

          return (
            !!not && (
              <ListItemButton
                key={not.id}
                disableRipple
                component={LinkWithLocale}
                href={"/notifications"}
                onClick={menuCtx.handleClose}
                divider
              >
                <ListTextStyled
                  primary={parse(not.parsedName ?? t("na"))}
                  secondary={
                    not.date ? new Date(not.date).toUTCString() : t("na")
                  }
                />
              </ListItemButton>
            )
          );
        })}
      </List>
      <ListItemButton
        onClick={() => {
          navbarNots.hide();
          setNoNotsText(t("cleared"));
        }}
      >
        <ListItemIcon>
          <VisibilityOff fontSize={"small"} />
        </ListItemIcon>
        <ListTextStyled primary={t("dismiss")} withoutColor />
      </ListItemButton>
    </List>
  );
};

export default NotificationsMenu;
