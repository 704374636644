/*
 * Copyright 2023-2024 NXP
 */

"use client";

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import LinkStyled from "@/src/components/UI/LinkStyled/LinkStyled";
import Image from "next/image";
import nxpLogo from "@/public/images/nxp-logo-color.svg";
import { Box, Stack } from "@mui/material";
import UserMenu from "@/src/components/layout/Navbar/UserMenu";
import ToolsNavigation from "@/src/components/layout/Navbar/ToolsNavigation";
import { MenuContextProvider } from "@/src/contexts/menuContext";
import { useTranslations } from "next-intl";
import { usePathname } from "@/src/navigation";
import { useAuthContext } from "@/src/contexts/authContext";

const Navbar = () => {
  const pathname = usePathname();
  const onConfigTools = ["/clock", "/pins"].includes(pathname);
  const user = useAuthContext().user?.myUser;
  const t = useTranslations("Navbar.Other");

  return (
    <AppBar sx={styles.appBar}>
      <Toolbar variant={"dense"} sx={styles.toolbar}>
        <LinkStyled href={"/"} color={"inherit"} underline={"none"}>
          <Stack direction={"row"} alignItems={"center"}>
            <Box sx={styles.logoImageContainer}>
              <Image
                src={nxpLogo}
                alt={"NXP logo"}
                width={70}
                style={styles.nxpLogo}
              />
            </Box>
            <Typography variant="h6" component="div" sx={styles.mainTitle}>
              {!onConfigTools ? t("title") : t("configToolsTitle")}
            </Typography>
          </Stack>
        </LinkStyled>
        {!!user && (
          <Stack direction={"row"} alignItems={"center"} spacing={4}>
            {onConfigTools && <ToolsNavigation />}
            <MenuContextProvider>
              <UserMenu />
            </MenuContextProvider>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
