/*
 * Copyright 2023-2024 NXP
 */

import React, { ReactNode } from "react";
import {
  GetNavbarNotificationsQueryHookResult,
  MyNotificationsSortEnum,
  useGetNavbarNotificationsLazyQuery,
  useGetUnseenNotsCountQuery,
  useHideQuickNotificationsMutation,
  useMarkNotificationsSeenMutation,
} from "@/web-builder/api";

type MenuContextType = {
  handleOpen: (menuName: string) => void;
  handleClose: () => void;
  openMenu: string;
  navbarNots: {
    count: number | undefined;
    hide: () => void;
    markAsSeen: () => void;
    queryRes: GetNavbarNotificationsQueryHookResult;
  };
};

interface MenuContextProviderProps {
  children: ReactNode;
}

const MenuContext = React.createContext<MenuContextType | null>(null);

const useMenuContext = () => {
  const menuContext = React.useContext(MenuContext);
  if (!menuContext) {
    throw new Error(
      "Cannot use `useMenuContext` outside of a MenuContextProvider",
    );
  }

  return menuContext;
};

const MenuContextProvider = ({ children }: MenuContextProviderProps) => {
  const [openMenu, setOpenMenu] = React.useState("");
  const [unseenNotsCount, setUnseenNotsCount] = React.useState<number>();

  const [getNavbarNots, queryRes] = useGetNavbarNotificationsLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [markNotsSeen] = useMarkNotificationsSeenMutation({
    onCompleted: () => setUnseenNotsCount(0),
  });
  const [hideNots] = useHideQuickNotificationsMutation({
    onCompleted: () => getNavbarNots().then(),
  });

  useGetUnseenNotsCountQuery({
    onCompleted: (res) => {
      if (unseenNotsCount != res.unseenCount) {
        getNavbarNots({
          variables: {
            first: 10,
            sort: MyNotificationsSortEnum.DATE_DESC,
            filter: { viewDeleted: { eq: false } },
          },
        }).then();

        setUnseenNotsCount(res.unseenCount);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    pollInterval: 10000,
  });

  const navbarNots = {
    count: unseenNotsCount,
    hide: hideNots,
    markAsSeen: markNotsSeen,
    queryRes,
  };

  const handleOpen = (menuName: string) => {
    setOpenMenu(menuName);
  };

  const handleClose = () => {
    setOpenMenu("");
  };

  return (
    <MenuContext.Provider
      value={{ handleOpen, handleClose, openMenu, navbarNots }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export { MenuContextProvider, useMenuContext };
