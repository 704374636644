/*
 * Copyright 2023-2024 NXP
 */

import React, { useTransition } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckIcon from "@mui/icons-material/Check";
import { ListItemTextCentered } from "@/src/components/UI/ListElementsStyled/ListElementsStyled";
import { usePathname, useRouter } from "@/src/navigation";
import { useLocale } from "next-intl";
import { useSearchParams } from "next/navigation";

const LanguageMenu = () => {
  const [isPending, startTransition] = useTransition();
  const searchParams = useSearchParams().toString();
  const pathName = usePathname();
  const router = useRouter();
  const activeLocale = useLocale();

  const languages = [
    {
      id: "en",
      lang: "English",
    },
    {
      id: "zh",
      lang: "简体中文",
    },
  ];

  const handleLanguageChange = (lang: 'en' | 'zh') => {
    if (activeLocale != lang) {
      startTransition(() => {
        router.replace(`${pathName}?${searchParams}`, { locale: lang });
      });
    }
  };

  return (
    <>
      {languages.map((lang) => (
        <ListItemButton
          key={lang.id}
          onClick={() => handleLanguageChange(lang.id as 'en' | 'zh')}
          dense
        >
          <ListItemIcon>
            {activeLocale == lang.id && <CheckIcon />}
          </ListItemIcon>
          <ListItemTextCentered primary={lang.lang} />
        </ListItemButton>
      ))}
    </>
  );
};

export default LanguageMenu;
