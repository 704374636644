/*
 * Copyright 2023-2024 NXP
 */

import * as React from "react";
import { ReactElement } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Badge,
  CollapseProps,
  Divider,
  IconButton,
  Toolbar,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import styles from "@/src/components/layout/Navbar/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const NestedMenuItemsContainer = (props: CollapseProps) => {
  return (
    <Collapse {...props}>
      <Divider />
      <Box sx={styles.collapsibleMenuItemsInnerContainer}>{props.children}</Box>
      <Divider />
    </Collapse>
  );
};

interface MenuItem {
  id: string;
  text: string;
  icon: ReactElement;
  content: ReactElement;
  unseenCount?: number;
  showOnSmallScreen?: boolean;
}

const CollapsibleMenu = ({ menuItems }: { menuItems: MenuItem[] }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [itemExpanded, setExpanded] = React.useState("");

  const handleMenuItemExpansion = (menuItem: MenuItem) => {
    if (itemExpanded != menuItem.id) {
      setExpanded(menuItem.id);
    } else {
      setExpanded("");
    }
  };

  const handleDrawerClose = () => {
    setMenuOpen(false);
    setExpanded("");
  };

  const toggleDrawer = (menuOpen: boolean) => {
    if (!menuOpen) {
      setExpanded("");
    }
    setMenuOpen(!menuOpen);
  };

  const list = (
    <Box sx={styles.collapsibleMenuItemsOuterContainer} role="presentation">
      <List>
        {menuItems.map((item) => (
          <Box
            key={item.id}
            sx={item.showOnSmallScreen ? styles.showOnSmallScreen : {}}
          >
            <ListItemButton
              selected={itemExpanded == item.id}
              onClick={() => handleMenuItemExpansion(item)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Badge
                    invisible={!item.unseenCount}
                    badgeContent={item.unseenCount}
                    color="error"
                    max={9}
                  >
                    <Box sx={styles.collapsibleMenuBadgeText}>{item.text}</Box>
                  </Badge>
                }
                disableTypography
              />
            </ListItemButton>
            <NestedMenuItemsContainer
              in={itemExpanded == item.id}
              timeout="auto"
              unmountOnExit
            >
              {item.content}
            </NestedMenuItemsContainer>
          </Box>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <IconButton
        sx={[styles.menuToggleButton, styles.userTab]}
        edge="start"
        color="inherit"
        onClick={() => toggleDrawer(menuOpen)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="top" open={menuOpen} onClose={handleDrawerClose}>
        {/* necessary for content to be below app bar */}
        <Toolbar variant="dense" />
        {list}
      </Drawer>
    </>
  );
};

export default CollapsibleMenu;
