/*
 * Copyright 2024 NXP
 */

import { Badge, BadgeProps, styled } from "@mui/material";
import React from "react";

export const StyledBadge = styled((props: BadgeProps) => {
  return (
    <Badge
      {...props}
      max={9}
      color={"error"}
      overlap={"circular"}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    />
  );
})<BadgeProps>({
  "& .MuiBadge-badge": {
    left: 2,
    bottom: 5,
    height: 15,
  },
});
